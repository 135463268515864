import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Form } from 'react-bootstrap';
import MultiSelectNoRef from 'components/common/MultiSelectNoRef';
import { getDepartmentOtionList } from 'services/customer-service/scActionPlanService';
const DepartmentDropdown = ({
  label,
  fieldName,
  handleChange,
  isMulti = true,
  placeholder = '',
  value,
  collapseValues = false,
  maxToShow = 4
}) => {
  const [options, setOptions] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    const fetchDepartment = async () => {
      const res = await getDepartmentOtionList(controller);
      setOptions(
        res?.map(x => {
          return {
            ...x,
            value: x.id,
            label: x.name
          };
        })
      );
    }
    fetchDepartment();
    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const handleValue = value => {
    if (typeof value === 'number') {
      return options?.find(x => x.value === value);
    } else if (typeof value === 'string') {
      const arr = value.split(',');
      return options?.filter(x => arr?.some(i => i.toString() == x.value));
    }
    return value;
  };

  return (
    <>
      {label && (
        <Flex alignItems="center" justifyContent="between">
          <Form.Label>{label}</Form.Label>
        </Flex>
      )}
      <MultiSelectNoRef
        closeMenuOnSelect={false}
        isMulti={isMulti}
        options={options}
        placeholder={placeholder}
        collapseValues={collapseValues}
        maxToShow={maxToShow}
        onChange={selectedOptions => {
          handleChange(fieldName, selectedOptions);
        }}
        value={handleValue(value)}
      />
    </>
  );
};

DepartmentDropdown.propTypes = {
  selectedOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]),
  collapseValues: PropTypes.bool,
  maxToShow: PropTypes.number
};

export default DepartmentDropdown;
