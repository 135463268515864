import React from 'react';
import IconButton from 'components/common/IconButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { createActionPlan } from 'services/customer-service/scActionPlanService';
import PropTypes from 'prop-types';

const ActionPlanTableHeader = ({ toggelFilter, meetingId }) => {
  const createNew = async () => {
    const result = await createActionPlan({ meetingId });
    console.log(result);
    if (result) {
      window.open(
        `/customer-service/meetings/${meetingId}/action-plan-manager/` + result.data,
        '_blank'
      );
    }
  };

  return (
    <div>
      <FalconCardHeader
        title="Running Action Plans"
        light
        titleTag="h6"
        endEl={
          <div id="orders-actions">
            {meetingId > 0 ? (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => createNew(meetingId)}
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
            ) : (
              ''
            )}
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
              onClick={toggelFilter}
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
          </div>
        }
      />
    </div>
  );
};

ActionPlanTableHeader.propTypes = {
  toggelFilter: PropTypes.func,
  meetingId: PropTypes.string
};

export default ActionPlanTableHeader;
