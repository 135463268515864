import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  getAtRiskODRs,
  getSupplierDeliveryChanges,
  getAgedODRs,
  getNewODRs,
  getNNCCustomers,
  getNewCCFs
} from 'services/meeting-management-services/odrStageDashboardService';
import { Spinner } from 'react-bootstrap';

const CustomersFocusWidget = () => {
  const [atRisksODRs, setAtRisksODRs] = useState({data:-1, url:''});
  const [supplierDeliveryChanges, setSDC] = useState({data:-1, url:''});
  const [agedODRs, setAgedODRs] = useState({data:-1, url:''});
  const [newODRs, setNewODRs] = useState({data:-1, url:''});
  const [newCCFs, setNewCCFs] = useState({data:-1, url:''});
  const [ncCustomers, setNcCustomers] = useState({data:-1, url:''});

  useEffect(() => {
    getAtRiskODRs().then(res => {
      setAtRisksODRs(res);
    });
    getSupplierDeliveryChanges().then(res => {
      setSDC(res);
    });
    getAgedODRs().then(res => {
      setAgedODRs(res);
    });
    getNewODRs().then(res => {
      setNewODRs(res);
    });
    getNNCCustomers().then(res => {
      setNcCustomers(res);
    });
    getNewCCFs().then(res => {
      setNewCCFs(res);
    });
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="At Risks ODRs"
            data={atRisksODRs.data}
            loading={atRisksODRs.data == -1}
            url={atRisksODRs.url}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="Supplier Delivery Changes"
            data={supplierDeliveryChanges.data}
            loading={supplierDeliveryChanges.data == -1}
            url={supplierDeliveryChanges.url}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="Aged ODRs"
            data={agedODRs.data}
            loading={agedODRs.data == -1}
            url={agedODRs.url}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="New ODRs"
            data={newODRs.data}
            loading={newODRs.data == -1}
            url={newODRs.url}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="New CCFs"
            data={newCCFs.data}
            loading={newCCFs.data == -1}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="N&C Customers"
            data={ncCustomers.data}
            loading={ncCustomers.data == -1}
            url={ncCustomers.url}
          />
        </Col>
      </Row>
    </>
  );
};

CustomersFocusWidget.propTypes = {
  meetingId: PropTypes.string
};

export default CustomersFocusWidget;
const CardNumberWidget = ({ title, data, loading, url }) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="flex-between-center g-0 h-100 align-items-center justify-content-center text-center">
          <Col>
            <h6 className="mb-2 text-900">{title}</h6>

            <h4 className="fs-3 fw-normal text-700 mb-0">
              {loading ? (
                <Spinner
                  variant="primary"
                  animation="border"
                  role="status"
                ></Spinner>
              ) : (
                <a
                  href={url == undefined ? '#' : url}
                  target={url == undefined ? '' : '_blank'} 
                  rel="noopener noreferrer"
                >
                  {data}
                </a>
              )}
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CardNumberWidget.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  loading: PropTypes.bool,
  url: PropTypes.string
};
