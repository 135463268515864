import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { departments } from 'data/TempData';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DepartmentDropdown from 'components/Dropdowns/CustomerServices/DepartmentDropdown';
import PriorityDropdown from 'components/Dropdowns/CustomerServices/PriorityDropdown';
import ProgressDropdown from 'components/Dropdowns/CustomerServices/ProgressDropdown';
import InputDatePicker from 'components/pages/customer-service/action-plans/create-action-plan/InputDatePicker';
const CustomersFocusFilter = ({
  formData,
  handleChange,
  handleSubmit,
  searchKeyword,
  isParentFullRow = false
}) => {
  const [searchValue, setSearchValue] = useState(searchKeyword);
  useEffect(() => {
    setSearchValue(searchKeyword);
  }, [searchKeyword]);
  return (
    <Card>
      <Card.Header as="h5">Fitler</Card.Header>
      <Card.Body className="bg-light">
        <Row
          style={{
            paddingBottom: '10px',
            maxHeight: '150px',
            overflowY: 'auto'
          }}
        >
          <Col lg={!isParentFullRow ? '4' : '3'}>
            {/* <Col lg={'4'}> */}
            <Form.Group controlId="eventTitle">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type="text"
                name="keyword"
                placeholder="Keyword"
                value={searchValue}
                onChange={e => {
                  handleChange('keyword', e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={!isParentFullRow ? '4' : '3'}>
            <InputDatePicker
              column="targetDate"
              displayName="Target Date"
              selected={formData?.targetDate}
              handleChange={handleChange}
            />
          </Col>
          <Col lg={!isParentFullRow ? '4' : '3'}>
            <ProgressDropdown
              label="Progresses"
              fieldName="progresses"
              isMulti
              placeholder="Progresses..."
              handleChange={handleChange}
              value={formData?.progresses}
            />
          </Col>
          <Col lg={!isParentFullRow ? '4' : '3'}>
            <UserDropdownV2
              label="Responsible persons"
              fieldName="assignees"
              handleChange={handleChange}
              placeholder="Responsible persons..."
              value={formData?.assignees}
            />
          </Col>
          <Col lg={!isParentFullRow ? '4' : '3'}>
            <UserDropdownV2
              label="Request participants"
              fieldName="requestParticipants"
              handleChange={handleChange}
              placeholder="Request participants..."
              value={formData?.requestParticipants}
            />
          </Col>
          <Col lg={!isParentFullRow ? '4' : '3'}>
            <UserDropdownV2
              label="Reporters"
              fieldName="reporters"
              handleChange={handleChange}
              placeholder="Reporters..."
              value={formData?.reporters}
              isMulti
            />
          </Col>
          <Col lg={!isParentFullRow ? '4' : '3'}>
            <DepartmentDropdown
              label="Departments"
              fieldName="departments"
              closeMenuOnSelect={false}
              isMulti
              options={departments}
              placeholder="Department"
              handleChange={handleChange}
              value={formData?.departments}
            />
          </Col>
          {/* <Col md="12">
            <div className="border-dashed-bottom"></div>
          </Col> */}
          <Col lg={!isParentFullRow ? '4' : '3'}>
            <Form.Group controlId="relativeJobNumbers">
              <Form.Label>Relative Job No</Form.Label>
              <Form.Control
                type="text"
                placeholder="Relative Job Numbers"
                name="relativeJobNumbers"
                value={formData?.relativeJobNumbers}
                onChange={e => {
                  handleChange('relativeJobNumbers', e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={!isParentFullRow ? '4' : '3'}>
            <PriorityDropdown
              label="Priorities"
              fieldName="priorities"
              closeMenuOnSelect={false}
              isMulti
              placeholder="Priorities..."
              handleChange={handleChange}
              value={formData?.priority}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <hr />
        <div className="text-center mt-2">
          <Button color="primary" onClick={handleSubmit}>
            Search
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};
CustomersFocusFilter.propTypes = {
  formData: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  searchKeyword: PropTypes.string,
  isParentFullRow: PropTypes.bool,
};

export default CustomersFocusFilter;
