import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import dayjs from 'dayjs';
import { priorites, progresses } from 'data/TempData';
import { getDepartmentOtionList } from 'services/customer-service/scActionPlanService';
import UsersInCellWidget from 'components/common/users/UsersInCellWidget';
import {
  getColumSettings,
  KEY_COL_SC_ACTION_PLAN
} from 'services/base-services/userSettingServices';

const CustomerFocusTableColumns = async () => {
  const dataDepartments = (await getDepartmentOtionList()).map(x => {
    return {
      ...x,
      value: x.id,
      label: x.name
    };
  });
  console.log(dataDepartments);
  const response = await getColumSettings(KEY_COL_SC_ACTION_PLAN);
  let columns = [];
  if (response && response.success) {
    columns = JSON.parse(response.data.settingValue);
  }

  const truncateDes = (description, maxLength, totalMaxLength) => {
    if (!description) return '';

    let truncated = '';
    let currentLength = 0;
    const parts = description.split(/(<img[^>]*>)/);

    parts.forEach(part => {
      if (part.startsWith('<img')) {
        truncated += part;
      } else {
        const words = part.split(' ');

        for (let i = 0; i < words.length; i++) {
          const word = words[i];
          if (currentLength + word.length > totalMaxLength) {
            truncated += '...';
            break;
          }
          truncated += word + ' ';
          currentLength += word.length + 1;
        }
      }
    });

    truncated = truncated.trim();

    if (truncated.length > totalMaxLength) {
      truncated = truncated.substring(0, totalMaxLength) + '...';
    }

    return truncated;
  };

  return [
    {
      accessor: 'id',
      Header: 'ID',
      isVisible: false
    },
    {
      accessor: 'meetingId',
      Header: 'MeetingId',
      isVisible: false
    },
    {
      accessor: 'description',
      Header: 'description',
      isVisible: false
    },
    {
      accessor: 'meetingTitle',
      Header: 'Meeting',
      Cell: rowData => {
        const { meetingTitle, meetingId } = rowData.row.original;
        return (
          <Link to={`/customer-service/meetings/${meetingId}`}>
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 style={{ color: '#0052CC' }} className="mb-0 fs--1">
                  {meetingTitle}
                </h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },

    {
      accessor: 'title',
      Header: 'Title',
      Cell: rowData => {
        const { title, id, meetingId, description } = rowData.row.original;
        return (
          <Link
            data-tooltip-id="my-tooltip"
            data-tooltip-place="right-start"
            data-tooltip-html={truncateDes(description, 200, 2000)}
            to={
              `/customer-service/meetings/${meetingId}/action-plan-manager/` +
              id
            }
          >
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 style={{ color: '#0052CC' }} className="mb-0 fs--1">
                  {title}
                </h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'targetDate',
      Header: 'Target Date',
      Cell: rowData => {
        const { targetDate } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">
                {targetDate && dayjs(targetDate).format('DD/MM/YYYY')}
              </h5>
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'assignees',
      Header: 'Responsible persons',
      Cell: rowData => {
        const { assignees } = rowData.row.original;
        return <UsersInCellWidget usersText={assignees} />;
      }
    },
    {
      accessor: 'requestParticipants',
      Header: 'Request participants',
      headerProps: { style: { minWidth: '10px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        const { requestParticipants } = rowData.row.original;
        return <UsersInCellWidget usersText={requestParticipants} />;
      }
    },
    {
      accessor: 'reporter',
      Header: 'Reporter',
      Cell: rowData => {
        const { reporter } = rowData.row.original;
        return (
          <UsersInCellWidget usersText={reporter?.toString()} />
        );
      }
    },
    {
      accessor: 'departments',
      Header: 'Departments',
      Cell: rowData => {
        const { departments } = rowData.row.original;
        const data = splitStringMapValueToDropDown(
          departments,
          dataDepartments
        );
        return (
          <>
            {data.map(i => (
              <Link to="#" key={i.value}>
                <Flex alignItems="center">
                  <div className="flex-1">
                    <h5 className="mb-0 fs--1">{i.label}</h5>
                  </div>
                </Flex>
              </Link>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'progress',
      Header: 'Progress',
      Cell: rowData => {
        const { progress } = rowData.row.original;
        const data = splitStringMapValueToDropDown(
          progress?.toString(),
          progresses
        );
        return (
          <>
            {data.map(i => (
              <Flex key={i.value} alignItems="center">
                <div className="flex-1">
                  <h5 className="mb-0 fs--1">{i.label}</h5>
                </div>
              </Flex>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'priority',
      Header: 'Priority',
      Cell: rowData => {
        const { priority } = rowData.row.original;
        const data = splitStringMapValueToDropDown(
          priority?.toString(),
          priorites
        );
        return (
          <>
            {data.map(i => (
              <Flex key={i.value} alignItems="center">
                <div className="flex-1">
                  <h5 className="mb-0 fs--1">{i.label}</h5>
                </div>
              </Flex>
            ))}
          </>
        );
      }
    }
  ].map(i => {
    const col = columns.find(x => x.accessor === i.accessor);
    return {
      ...i,
      isVisible: col ? col.isVisible : i.isVisible
    };
  });
};

const CustomersFocusTable = ({
  reload,
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  onPageSizeChange
}) => {
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    const med = async () => {
      const initialCols = await CustomerFocusTableColumns(reload);
      setColumns(initialCols);
    };

    med();
  }, [reload]);

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        textAlign=""
      />
    </>
  );
};

CustomersFocusTable.propTypes = {
  reload: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func
};

export { CustomersFocusTable, CustomerFocusTableColumns };
