import React, { useState, useEffect } from "react";
import { Card, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import PermissionSetupTable from "components/partials/permissions/permission-setup/PermissionSetupTable";
import { searchPermissionSetups } from "services/permissions/permissionSetupServices";
import { BaseTableRQ } from "components/pages/stock-location/models/BaseTable"

export default function PermissionSetupList() {
    const [permissionSetups, setPermissionSetups] = useState([]);
    const [total, setTotal] = useState(0);
    const [paging, setPaging] = useState(new BaseTableRQ(1, 100));

    useEffect(() => {
        loadPermissionSetups(paging);
    }, [])

    const loadPermissionSetups = (pagingRq) => {
        searchPermissionSetups(pagingRq).then(res => {
            if (res) {
                setPermissionSetups(res.items);
                setTotal(res.total);
            }
        })
            .catch(err => {
                console.log(err);
            });

        setPaging(pagingRq);
    }

    const gotoPage = (index) => {
        index += 1;
        if (index <= 0) return;
        paging.pageNum = index;
        loadPermissionSetups(paging);
    }

    const nextPage = () => {
        const page = paging.pageNum + 1;
        if (page > Math.ceil(total / paging.pageSize)) return;
        paging.pageNum = page;
        loadPermissionSetups(paging);
    }

    const previousPage = () => {
        const page = paging.pageNum - 1;
        if (page <= 0) return;
        paging.pageNum = page;
        loadPermissionSetups(paging);
    }

    return (
        <>
            <PageHeader
                title="Permission Setup List"
                description={`Manage and view all available Permission Setup from this page.`}
                className="mb-3"
            ></PageHeader>
            <Card>
                <Card.Body className="overflow-hidden p-lg-6">
                    <Row className="align-items-center justify-content-between">
                        <PermissionSetupTable
                            data={permissionSetups}
                            paging={paging}
                            total={total}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            gotoPage={gotoPage}
                        />
                    </Row>
                </Card.Body>
            </Card>
        </>)
}