import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import UsersInCellWidget from 'components/common/users/UsersInCellWidget';
const columns = [
  {
    accessor: 'evaUserId',
    Header: 'Eva User Id',
    Cell: rowData => {
      console.log(rowData)
      const { evaUserId } = rowData.row.original;
      return <div style={{alignItems:'center'}}><UsersInCellWidget usersText={evaUserId?.toString()} /></div>;
    }
  },
  {
    accessor: 'pageName',
    Header: 'Page Name'
  },
  {
    accessor: 'viewPermission',
    Header: 'View',
    Cell: rowData => {
      const { viewPermission } = rowData.row.original;
      return (
        <input
          type="checkbox"
          id="myCheckbox"
          name="myCheckbox"
          checked={viewPermission}
        />
      );
    }
  },
  {
    accessor: 'createPermission',
    Header: 'Create',
    Cell: rowData => {
      const { createPermission } = rowData.row.original;
      return (
        <input
          type="checkbox"
          id="myCheckbox"
          name="myCheckbox"
          checked={createPermission}
        />
      );
    }
  },
  {
    accessor: 'updatePermission',
    Header: 'Update',
    Cell: rowData => {
      const { updatePermission } = rowData.row.original;
      return (
        <input
          type="checkbox"
          id="myCheckbox"
          name="myCheckbox"
          checked={updatePermission}
        />
      );
    }
  },
  {
    accessor: 'deletePermission',
    Header: 'Delete',
    Cell: rowData => {
      const { deletePermission } = rowData.row.original;
      return (
        <input
          type="checkbox"
          id="myCheckbox"
          name="myCheckbox"
          checked={deletePermission}
        />
      );
    }
  },
  {
    accessor: 'action',
    Header: 'Action',
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <Link
          to={`/admin/permission/user-permission/user-permission-manager?id=${id}`}
        >
          Edit
        </Link>
      );
    }
  }
];

const UserPermissionTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  onPageSizeChange
}) => {
  return (
    <PartialTable
      data={data}
      columns={columns}
      paging={paging}
      total={total}
      nextPage={nextPage}
      previousPage={previousPage}
      gotoPage={gotoPage}
      onPageSizeChange={onPageSizeChange}
      // view={'/admin/permission/user-permission/user-permission-manager?id='}
    />
  );
};

UserPermissionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func
};

export default UserPermissionTable;
