import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { useForm } from 'react-hook-form';
import ActionPlanFooter from './ActionPlanFooter';
import {
  getActionPlan,
  patchActionPlan
} from 'services/customer-service/scActionPlanService';
import { toast } from 'react-toastify';
import BasicTemplate from 'components/Toast/BasicTemplate';
import { useParams } from 'react-router-dom';
import ActionPlanRightInfo from './ActionPlanRightInfo';
import Editor from './Editor';
import { Link } from 'react-router-dom';
export default function ActionPlanManager() {
  useEffect(() => {
    fetchData();
  }, []);
  const { actionPlanId, meetingId } = useParams();
  const imageFolder =
    process.env.REACT_APP_CUSTOMER_SERVICE_FOLDER +
    `/meetings/${meetingId}/sc_action_plans/${actionPlanId}`;
  const [description, setDescription] = useState('');
  const fetchData = () => {
    getActionPlan(actionPlanId).then(res => {
      if (res?.data && res?.data.id > 0) {
        const mapData = mapRSData(res?.data);
        console.log(mapData);
        setFormData(mapData);
        setDescription(mapData.description);
        for (const key in mapData) {
          setValue(key, mapData[key], { shouldValidate: false });
        }
      } else {
        window.location.href = '/notfound';
      }
    });
  };
  const defaultValues = {
    id: 0,
    title: '',
    description: '',
    meetingTitle: ''
  };

  const [formData, setFormData] = useState(defaultValues);

  const handleChange = (name, value) => {
    console.log(name, value);
    setValue(name, value);
    // trigger(name);
    setFormData({
      ...formData,
      [name]: value
    });
    setValue(name, value, { shouldValidate: false });
  };

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues
  });

  const onSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      var rqData = mapRQData(formData);
      rqData.description = description;
      let result = null;
      result = await patchActionPlan(rqData, formData.id);
      const message =
        result.message ??
        'There was a problem saving the Action Plan. Please check your entires and try again.';
      if (result?.success) {
        toast.success(
          <BasicTemplate
            success={result.success}
            text="Action Plan successfully saved!"
          />
        );
        return;
      }

      toast.error(<BasicTemplate success={result.success} text={message} />);
      window.scrollTo(0, 0);
    } else {
      toast.error(
        <BasicTemplate
          success={false}
          text={'Please correct the errors in the form before saving.'}
        />
      );
      window.scrollTo(0, 0);
    }
  };

  const mapRQData = data => {
    
  
    if (data) {
      let mapRq = {
        id: data.id,
        title: data.title,
        description: data.description,
        meetingTitle: data.meetingTitle
      };
      return mapRq;
    }
    return null;
  };

  const mapRSData = data => {
    if (data) {
      let mapRs = { ...data };
      if (data.targetDate) {
        mapRs.targetDate = new Date(data.targetDate);
      }
      return mapRs;
    }
    return defaultValues;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12}>
            <PageHeader
              title={
                <div>
                  <Link to={"/customer-service/meetings/" + formData?.meetingId }>
                    {'Meeting : ' + formData?.meetingTitle}
                  </Link>
                  <div>
                   
                  </div>
                </div>
              }
              description={formData?.title
                ? 'Edit Action Plan'
                : 'Create Action Plan'}
              className="mb-3"
            />
          </Col>
          <Col lg={8} md={12}>
            <Card className="mb-3">
              <Card.Header as="h5">
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">Details</h5>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="bg-light">
                <Row className="gx-2 gy-3">
                  <Col md="12">
                    <Form.Group controlId="title">
                      <Form.Label className="required">Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        onClick={i => {
                          console.log(i);
                        }}
                        placeholder="Title..."
                        {...register('title', { required: true })}
                        value={formData.title}
                        onChange={e => {
                          handleChange('title', e.target.value);
                        }}
                      />
                      {errors.title && (
                        <Form.Label style={{ marginTop: '10px', color: 'red' }}>
                          This field is required
                        </Form.Label>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Row rows={12}>
                      <Form.Label>Description</Form.Label>
                      <Editor
                        imageFolder={imageFolder}
                        initialText={formData.description}
                        handleChangeOut={html => setDescription(html)}
                      />
                    </Row>
                  </Col>
                  <Col md="12">
                    <Row rows={12}>
                      <Col md></Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          type="submit"
                        >
                          {formData?.title ? 'Save' : 'Create'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {actionPlanId && <ActionPlanFooter id={actionPlanId} />}
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <ActionPlanRightInfo
                headerText="Other info"
                id={formData?.id}
                // handleChange={handleChange}
                // setValue={setValue}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}
