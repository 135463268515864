import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import MeetingTable from './MeetingTable';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { searchPagingMeetings } from 'services/customer-service/scMeetingService'

import PropTypes from 'prop-types';

const RunningMeeting = ({ meetingId, pageSize = 100 }) => {
  const defaultRQ = { ...new BaseTableRQ(1, pageSize), meetingId: meetingId };
  const [meetings, setMeetings] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    loadMeetings(paging);
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    loadMeetings(paging);
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    loadMeetings(paging);
  };

  useEffect(() => {
    loadMeetings(defaultRQ);
  }, []);

  const loadMeetings = pagingRq => {
    searchPagingMeetings(pagingRq)
      .then(res => {
        if (res) {
          setMeetings(res.items);
          setTotal(res.total);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setPaging(pagingRq);
  };

  return (
    <>
      <Card>
        <Row style={{ padding: '10px' }}>
          {/* <MeetingTableHeader
            toggelFilter={() => toggelFilter()}
            meetingId={meetingId}
            style={{ padding: '10px' }}
          /> */}
          <Col lg={12}>
            <MeetingTable
              data={meetings}
              paging={paging}
              total={total}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

RunningMeeting.propTypes = {
  meetingId: PropTypes.string,
  pageSize:  PropTypes.number,
};

export default RunningMeeting;
