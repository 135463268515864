import axios from 'axios';
import { URL } from '../../helpers/urlHelper';
import { getMessage400Request } from '../../helpers/utils';
import { ApiResponse } from '../../helpers/stockLocationHelpers';
axios.defaults.withCredentials = true;
const baseURL = `${URL}/user-settings`;

const KEY_COL_SC_ACTION_PLAN = 'KEY_COL_SC_ACTION_PLAN';

const getColumSettings = async key => {
  const result = new ApiResponse();
  const res = await axios({
    method: 'get',
    url: `${baseURL}/${key}`
  }).catch(res => {
    console.log('ERROR: ', { ...res });
    result.message = getMessage400Request(res);
    return result;
  });

  if (res.status !== 200) {
    return result;
  }
  result.success = res.data.success;
  result.data = res.data?.data;
  result.message = res.data?.message;
  return result;
  // return columns;
};

const saveSettings = async (key, value) => {
  const result = new ApiResponse();
  if (!key || !value) return result;
  const res = await axios({
    method: 'patch',
    url: baseURL,
    data: { settingKey: key, settingValue: value },
    config: {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    }
  }).catch(error => {
    console.log('ERROR: ', { ...error });
    return result;
  });
  if (res.status !== 200) {
    return result;
  }

  result.success = res.data.success;
  result.data = res.data?.data;
  result.message = res.data?.message;
  return result;
};

export { getColumSettings, saveSettings, KEY_COL_SC_ACTION_PLAN };
