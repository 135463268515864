import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import CustomersFocusTableHeader from './CustomersFocusTableHeader';
import { CustomersFocusTable } from './CustomersFocusTable';
import { joinDataMultiDropdown } from 'helpers/dropdownFuncHelper';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { searchCustomersFocus } from 'services/meeting-management-services/mmCustomersFocusService';
import CustomersFocusFilter from './CustomersFocusFilter';
import PropTypes from 'prop-types';

const RunningCustomersFocus = ({
  meetingId,
  pageSize = 2147483647,
  isFullRow = true
}) => {
  const [filter, setFilter] = useState({ progresses: '1,2' });
  const defaultRQ = {
    ...new BaseTableRQ(1, pageSize),
    ...filter,
    meetingId: meetingId
  };
  const [actionPlans, setCustomersFocuss] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });
  const [isFilter, setIsFilter] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setIsFilter(false);
  }, []);

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    loadCustomersFocuss(paging);
  };

  const toggelFilter = () => {
    setIsFilter(!isFilter);
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    loadCustomersFocuss(paging);
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    loadCustomersFocuss(paging);
  };

  const handleChange = (field, value) => {
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const handleSubmit = () => {
    let rq = mapRQData(filter);
    loadCustomersFocuss({ ...defaultRQ, ...rq });
    setReload(!reload);
  };

  const mapRQData = data => {
    if (data) {
      let mapRq = { ...data };
      if (data?.targetDate) {
        mapRq.targetDate = data?.targetDate?.toISOString();
      }

      mapRq.progresses = joinDataMultiDropdown(data?.progresses);
      mapRq.reporters = joinDataMultiDropdown(data?.reporters);
      mapRq.priorities = joinDataMultiDropdown(data?.priorities);
      mapRq.assignees = joinDataMultiDropdown(data?.assignees);
      mapRq.requestParticipants = joinDataMultiDropdown(
        data?.requestParticipants
      );
      mapRq.departments = joinDataMultiDropdown(data?.departments);
      return mapRq;
    }
    return null;
  };

  useEffect(() => {
    loadCustomersFocuss(defaultRQ);
  }, []);

  const loadCustomersFocuss = pagingRq => {
    searchCustomersFocus(pagingRq)
      .then(res => {
        if (res) {
          setCustomersFocuss(res.items);
          setTotal(res.total);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setPaging(pagingRq);
  };

  return (
    <>
      <Card>
        <Row style={{ padding: '10px' }}>
          <CustomersFocusTableHeader
            toggelFilter={() => toggelFilter()}
            meetingId={meetingId}
            style={{ padding: '10px' }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isFilter={isFilter}
            setReload={setReload}
            reload={reload}
          />
          {isFilter && (
            <Col lg={12} style={{ paddingBottom: '10px', paddingTop: '10px' }}>
              <CustomersFocusFilter
                isParentFullRow={isFullRow}
                handleChange={handleChange}
                formData={filter}
                handleSubmit={handleSubmit}
              />
            </Col>
          )}
          <Col lg={12}>
            <CustomersFocusTable
              data={actionPlans}
              paging={paging}
              total={total}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              reload={reload}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

RunningCustomersFocus.propTypes = {
  meetingId: PropTypes.string,
  pageSize: PropTypes.number,
  isFullRow : PropTypes.bool,
};

export default RunningCustomersFocus;

const CardNumberWidget = ({ title, data }) => {
  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="flex-between-center g-0 h-100 align-items-center justify-content-center text-center">
          <Col>
            <h6 className="mb-2 text-900">{title}</h6>
            <h4 className="fs-3 fw-normal text-700 mb-0">{data}</h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CardNumberWidget.propTypes = {
  meetingId: PropTypes.number,
  pageSize : PropTypes.number,
  isFullRow : PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.string,
};
