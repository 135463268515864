import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import TimeAgo from './TimeAgo';
import { getAllEvaUsers } from 'services/userServices';
import CommentEditor from './CommentEditor';
import { patchComment } from 'services/customer-service/scActionPlanComment';

const Comment = ({
  id,
  createdBy,
  description,
  updated,
  idEditting,
  handleEditting
}) => {
  const [idEditingLocal, setIdEditingLocal] = useState(idEditting);
  useEffect(() => {
    setIdEditingLocal(idEditting);
  }, [idEditting]);

  const [users, setUsers] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();

    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length) setUsers(res);
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const getUsername = createdBy => {
    const createdUser = users?.find(i => i.userId == createdBy);

    if (createdUser) {
      return createdUser.name;
    }
    return '';
  };

  const handleEditCancel = () => {
    handleEditting(0);
  };

  const handleEditSave = async value => {
    await patchComment({ Description: value }, id);
    handleEditting(0);
  };

  return (
    <>
      {id === idEditingLocal ? (
        <CommentEditor
          userName={getUsername(createdBy)}
          handleSave={value => handleEditSave(value)}
          commentIn={description}
          handleCancel={handleEditCancel}
          updated={updated}
        />
      ) : (
        <Flex className="mt-3">
          <Avatar name={getUsername(createdBy)} size="xl" />
          <div className="flex-1 ms-2 fs--1">
            <p className="mb-1 bg-200 rounded-3 p-2">
              <Link
                className="fw-semi-bold"
                to="/user/profile"
                style={{ marginRight: '10px' }}
              >
                {getUsername(createdBy)}
              </Link>
              <TimeAgo timestamp={updated} />
              <span
                className="commment"
                dangerouslySetInnerHTML={createMarkup(description)}
              />
            </p>
            <div className="px-2">
              <span
                style={{ color: '#2c7be5' }}
                onClick={() => handleEditting(id)}
              >
                Edit
              </span>{' '}
              • <a href="#!">Delete</a>
            </div>
          </div>
        </Flex>
      )}
    </>
  );
};

Comment.propTypes = {
  id: PropTypes.number,
  createdBy: PropTypes.number,
  description: PropTypes.string,
  updated: PropTypes.string,
  idEditting: PropTypes.number,
  handleEditting: PropTypes.func
};

export default Comment;
