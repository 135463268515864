import React from "react";
import { Row, Col } from 'react-bootstrap';
import RunningCustomersFocus from 'components/pages/meeting-management/customers-focus-dashboard/RunningCustomersFocus';
const HomeWidgetDashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <RunningCustomersFocus pageSize={5} isFullRow={false}/>
        </Col>
      </Row>
    </>
  );
};

export default HomeWidgetDashboard;
