import axios from 'axios';
axios.defaults.withCredentials = true;

export const UploadFile = async (prefix, file) => {
  const formData = new FormData();
  formData.append('files', file);
  const bucketName = 'eva-files';
  // const url = 'https://file-api.emplas.co.uk/v1';
  const url = process.env.REACT_APP_FILE_API_HOST; //'https://localhost:7262/v1';
  var fullURL = `${url}/eva/multi-upload?bucketName=${bucketName}&prefix=${prefix}`;

  const res = await axios({
    url: fullURL,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  const fileName = res?.data?.mappings[0].hashedName;
  const returnUrl = `${url}/files/download/${bucketName}?key=${prefix}/${fileName}`;
  return returnUrl;
};
