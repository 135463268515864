import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import RunningMeeting from './RunningMeeting';
const MeetingDashboard = () => {
  return (
    <>
      <PageHeader
        title="Meeting Dashboard"
        description={``}
        className="mb-3"
        col={12}
      > <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            localStorage.setItem('isOpenScheduleModal', 'true');
            window.open(
              `/customer-service/meetings/calendar`,
              '_blank'
            );
          }}
        >
          Add meeting
        </Button>
    </div></PageHeader>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <RunningMeeting />
        </Col>
      </Row>
    </>
  );
};

export default MeetingDashboard;
