import { weekday } from 'components/pages/customer-service/meetings/calendar/RecurrenceModal';
import { datetime } from 'rrule';
export const parseSourceToRuleParam = source => {
  const parsedObject = JSON.parse(source);
  const lowerCaseObject = Object.keys(parsedObject).reduce((acc, key) => {
    acc[key.toLowerCase()] = parsedObject[key];
    return acc;
  }, {});

  lowerCaseObject.dtstart = mapToRDate(
    utcToLocal(new Date(lowerCaseObject.dtstart))
  );

  lowerCaseObject.until = mapToRDate(utcToLocal(new Date(lowerCaseObject.until))); // moment.utc(lowerCaseObject.until).toDate();
  lowerCaseObject.byweekday = weekday
    .filter(i => lowerCaseObject.byweekday?.some(x => x == i.id.weekday))
    .map(i => i.id);

  return lowerCaseObject;
};

export const mapToRDate = data => {
  const date = datetime(
    data.getUTCFullYear(),
    data.getUTCMonth() + 1,
    data.getUTCDate(),
    data.getUTCHours(),
    data.getUTCMinutes(),
    data.getUTCSeconds()
  );
  return date
};

export const utcToLocal = utcDate => {
  const localDate = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
  );
  return localDate;
};

export const localToUTC = localDate => {
  const utcDate = new Date(
    localDate.getTime() + localDate.getTimezoneOffset() * 60000
  );
  return utcDate;
};
