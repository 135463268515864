import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { post } from 'services/skill-matric-services/smSectionService';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';

const SectionModal = ({
  id,
  isShow,
  handleClose,
  handleSubmit
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [formData, setFormData] = useState({});

  const handleDropdown = (field, value) => {
    setFormData({ ...formData, [field]: value.userId });
  };

  const handleChange = ({ target }) => {
    console.log(formData);
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async e => {
    console.log('submit')
    e.preventDefault();
    await post({ id: uuid(), ...formData });
    handleSubmit();
  };

  useEffect(() => {

  }, []);

  return (
    <Modal
      show={isShow}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">{id ? "Edit" : "Create"} Section</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0 required">Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              onChange={handleChange}
            />
          </Form.Group>
          <Col md="12">
            <Form.Label className="fs-0">Supervisor</Form.Label>
            <UserDropdownV2
              fieldName="supervisor"
              isMulti={false}
              handleChange={handleDropdown}
              placeholder="Supervisor"
            />
          </Col>
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0 required">Point Of Contact</Form.Label>
            <Form.Control
              type="text"
              name="pointOfContact"
              required
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Link to="#!" className="me-3 text-600">
            More options
          </Link>
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SectionModal.propTypes = {
  id: PropTypes.number,
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default SectionModal;
